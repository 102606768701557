<template>
  <div class="row">
    <div class="col-xxl-4">
      <h1>Formularz suplementów</h1>

      <b-form-group label="Nazwa">
        <b-form-input v-model="suplement.name" />
      </b-form-group>

      <b-form-group label="Seria">
        <b-form-input v-model="suplement.series" />
      </b-form-group>

      <b-button @click="storeData" variant="primary" class="mt-3"
        >Zapisz</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "suplementForm",
  data() {
    return {
      suplement: {
        name: "",
        series: "",
        deleted: 0,
      },
    };
  },
  methods: {
    storeData() {
      let payload = {
        ...this.suplement,
      };

      if (this.$route.params.id)
        this.$store.dispatch("suplementEdit", {
          id: this.$route.params.id,
          ...payload,
        });
      else this.$store.dispatch("suplementAdd", payload);

      this.$store.dispatch("suplementList");

      this.$router.push("/lab/suplement");
    },
  },
  watch: {
    suplements: {
      handler() {
        let items = this.suplements.filter(
          (el) => el.id == this.$route.params.id
        );

        if (items.length > 0) {
          this.suplement.name = items[0].name;
          this.suplement.series = items[0].series;
          this.suplement.deleted = items[0].deleted;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["suplements"]),
  },
  mounted() {
    this.$store.dispatch("suplementList");
  },
};
</script>

<style scoped></style>
